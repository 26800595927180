import * as React from "react"
import { useState, useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Disclosure, Transition } from '@headlessui/react'
import Countdown, { zeroPad } from 'react-countdown';

import parse from "html-react-parser";


import {
  CheckIcon, LockClosedIcon,
  CashIcon,
  UserGroupIcon,
  BanIcon,
  ShareIcon,
  FilterIcon,
  ScaleIcon,
  ExternalLinkIcon,
  InformationCircleIcon,
  TrendingUpIcon
} from '@heroicons/react/solid'

import {


  ChevronDownIcon
} from '@heroicons/react/outline'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Cta from "../components/cta"

import Launch from "../images/launch.svg"
import Growth from "../images/growth.svg"
import Moon from "../images/moon.svg"

import TheGoodness from "../images/the-goodness.svg"
import Hepburne from "../images/hepburne.svg"
import Spark from "../images/spark.svg"
import Lloyd from "../images/lloyd.svg"

import CoinGecko from "../images/coingecko.svg"
import CoinMarketCap from "../images/coinmarketcap.svg"
import BSCScan from "../images/bscscan.svg"


const features = [
  {
    name: 'Professional Marketing',
    description: 'Our marketing wallet was allocated 5% of total tokens',
    icon: ShareIcon,
  },
  {
    name: 'Experienced & Professional Team',
    description: 'Each team member was allocated 1% of total tokens each',
    icon: UserGroupIcon,
  },
  {
    name: '10% Ethereum Distribution',
    description: 'A minimum of 200k tokens is required to be held to qualify for dividend distribution',
    icon: CashIcon,
  },
  {
    name: '5% Auto-LP',
    description: '',
    icon: TrendingUpIcon,
  },
  {
    name: 'Anti-Dump',
    description: '',
    icon: FilterIcon,
  },
  {
    name: 'Anti-Whale',
    description: '',
    icon: ScaleIcon,
  },

]



const team = [
  {
    name: 'The Goodness',
    role: 'CEO',
    imageUrl: TheGoodness,
  },
  {
    name: 'Hepburne',
    role: 'Lead Dev',
    imageUrl: Hepburne,
  },
  {
    name: 'Spark',
    role: 'Marketing Specialist',
    imageUrl: Spark,
  },
  {
    name: 'Lloyd',
    role: 'Socials and Partnerships',
    imageUrl: Lloyd,
  },
]

const faqs = [
  {
    question: "What is ShibaETH?",
    answer:
      "A New Era In The Shiba Hype! Earn ETH while Holding ShibaETH. Yes, our distribution is Ethereum! 10% will be re-distributed among our community! 💰",
  },
  {
    question: "How do I buy ShibaETH?",
    answer:
      "You can buy the tokens on <a href='https://app.uniswap.org/#/swap?outputCurrency=0x440c031d48cac6a2242521a2bcd0574bf74f6cac&use=V2' target='_blank' class='text-indigo-700 underline'>Uniswap</a>. Make sure you click the Settings icon on Uniswap and set the Slippage Tolerance to <strong>15%</strong>",
  },
  {
    question: "Unable to buy ShibaETH on Uniswap?",
    answer:
      "Click the Settings icon on Uniswap and set the Slippage Tolerance to 15%. If it still doesn't work, contact us. One of our team, or someone from the community will be happy to help!",
  },
  {
    question: "Unable to sell ShibaETH on Uniswap?",
    answer:
      "Click the Settings icon on Uniswap and set the Slippage Tolerance to 18%. If it still doesn't work, contact us. One of our team, or someone from the community will be happy to help!",
  },
  {
    question: "How do I get the ETH rewards?",
    answer:
      "You'll automatically receive ETH tokens when you hold. Sit back, relax and watch your wallet grow! A minimum of 200k tokens is required to be held to qualify for dividend distribution",
  },
  {
    question: "How can I contact you?",
    answer:
      "You may contact us on Telegram or email us directly at contact@shibaeth.finance",
  },
]


const roadmap = [
  [
    {
      content: 'Website Launch',
      icon: CheckIcon,
      iconBackground: 'bg-indigo-500',
    },
    
    {
      content: 'Multi-Channel Marketing',
      icon: CheckIcon,
      iconBackground: 'bg-indigo-500',
    },
    {
      content: 'Advertising Campaigns',
      icon: CheckIcon,
      iconBackground: 'bg-indigo-500',
    },
    {
      content: '1,000 Telegram Members',
      icon: CheckIcon,
      iconBackground: 'bg-indigo-500',
    },
    {
      content: 'Uniswap Listing',
      icon: CheckIcon,
      iconBackground: 'bg-indigo-500',
    },
    {
      content: 'Dividend Dashboard with user statistics & manual claiming',
      icon: CheckIcon,
      iconBackground: 'bg-indigo-500',
    },
    {
      content: '1,000+ Holders',
      icon: null,
      iconBackground: 'bg-indigo-100',
    },
    {
      content: '$500,000+ Market Cap',
      icon: null,
      iconBackground: 'bg-indigo-100',
    },
    {
      content: 'CoinGecko Listing',
      icon: null,
      iconBackground: 'bg-indigo-100',
    },
    {
      content: 'Certik Audit',
      icon: null,
      iconBackground: 'bg-indigo-100',
    },
  ],
  [
    {
      content: 'CoinMarketCap Listing',
      icon: null,
      iconBackground: 'bg-indigo-100',
    },
    {
      content: 'WhitePaper Release',
      icon: null,
      iconBackground: 'bg-indigo-100',
    },
    {
      content: '$5 Million+ Market Cap',
      icon: null,
      iconBackground: 'bg-indigo-100',
    },
    {
      content: '2,500+ Holders',
      icon: null,
      iconBackground: 'bg-indigo-100',
    },
    {
      content: '5,000 Telegram Members',
      icon: null,
      iconBackground: 'bg-indigo-100',
    },
    {
      content: 'Blockfolio Listing',
      icon: null,
      iconBackground: 'bg-indigo-100',
    },
    {
      content: 'Trustwallet Logo',
      icon: null,
      iconBackground: 'bg-indigo-100',
    },
  ],

  [
    {
      content: '$25 Million+ Market Cap',
      icon: null,
      iconBackground: 'bg-indigo-100',
    },
    {
      content: '10,000+ Holders',
      icon: null,
      iconBackground: 'bg-indigo-100',
    },
    {
      content: '25,000 Telegram Members',
      icon: null,
      iconBackground: 'bg-indigo-100',
    },
    {
      content: 'NFT Platform',
      icon: null,
      iconBackground: 'bg-indigo-100',
    },
    {
      content: 'Exchanges Listing',
      icon: null,
      iconBackground: 'bg-indigo-100',
    },
  ],


]

const quarters = [
  {
    quarter: 'Launch',
    icon: Launch
  },
  {
    quarter: 'Growth',
    icon: Growth
  },
  {
    quarter: 'Moon',
    icon: Moon
  }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const IndexPage = () => {
  let totalEth
  const [totalLoaded, setTotalLoaded] = useState(false)
  const [total, setTotal] = useState([])
  const [ethPriceLoaded, setEthPriceLoaded] = useState(false)
  const [ethPrice, setEthPrice] = useState([])
  useEffect(() => {
    fetch(`https://api.shibaeth.finance/get-total`)
      .then(response => {
        return response.json()
      }).then(json => {
        setTotal(json)
        totalEth = json;
        setTotalLoaded(true)
        fetch(`https://api.shibaeth.finance/get-eth-price`)
          .then(response => {
            return response.json()
          }).then(json => {
            setEthPrice(parseInt(parseFloat(totalEth) * parseFloat(json), 10).toLocaleString())
            setEthPriceLoaded(true)
          })
      })


  }, [])
  return (
    <Layout>
      <Seo

        title="Earn ETH while Holding ShibaETH"

      />




      <div className="relative bg-gray-900 overflow-hidden">
        <div className="relative pt-6 pb-12 sm:pb-24 lg:pb-32">
          <main className="mt-8 sm:mt-14 lg:mt-16 container">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8 items-center">
              <div className="text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left mb-3 md:mb-6 lg:mb-0">
                <p className="text-base md:text-lg lg:text-xl font-bold tracking-wider text-indigo-400 uppercase mb-3 md:mb-4">A new era in the Shiba Hype!</p>

                <h1 className="text-4xl md:text-6xl xl:text-7xl font-display font-bold mb-4 lg:mb-6 xl:mb-8 max-w-4xl text-white">
                  Earn ETH while Holding Shiba<span className="text-indigo-400">ETH</span></h1>
                <p className=" text-lg md:text-lg lg:text-xl xl:text-2xl xl:leading-relaxed mb-4 lg:mb-6 xl:mb-7 max-w-2xl text-gray-100">Yes, our distribution is Ethereum! 10% will be re-distributed among our community!</p>
                <div className="bg-gray-800 rounded-lg py-4 px-5 md:py-5 md:px-7 inline-block mb-4 lg:mb-6 xl:mb-7">
                  <h5 className="text-xs md:text-sm font-medium tracking-wider text-indigo-400 uppercase mb-0.5">Total Distributed</h5>
                  <div className="text-2xl md:text-3xl text-white font-medium flex items-center mt-1 leading-tight md:leading-tight">
                    {totalLoaded
                      ?
                      <Transition
                        show={totalLoaded}
                        enter="transition-opacity duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                      >
                        <span>{total}</span>
                      </Transition>

                      :
                      <span className="bg-gray-400 rounded-full animate-pulse"><span className="opacity-0">0.000000</span></span>}
                    <span className="text-gray-300 font-normal text-base md:text-xl ml-2"> ETH</span>
                  </div>
                  <div className="text-xs md:text-sm text-gray-400 inline-flex items-center mx-auto lg:mx-0 leading-tight md:leading-tight">
                  {ethPriceLoaded
                      ?
                      <Transition
                        show={ethPriceLoaded}
                        enter="transition-opacity duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                      >
                        <span>~ ${ethPrice}</span>
                      </Transition>

                      :
                      <span className="bg-gray-400 rounded-full animate-pulse"><span className="opacity-0">~ $0000000</span></span>}
                      <span className="ml-1"> USD</span>
                  </div>

                </div>
                <div>
<div className="-ml-1.5 -mr-1.5 md:-ml-2 md:-mr-2 flex items-center flex-wrap justify-center lg:justify-start">
  <a href="https://app.uniswap.org/#/swap?outputCurrency=0x440c031d48cac6a2242521a2bcd0574bf74f6cac&use=V2" target="_blank" className="flex items-center flex-shrink-0 text-white bg-indigo-600 transform hover:bg-indigo-500  px-5 py-3 lg:px-10 lg:py-5 mt-2 text-xs md:text-sm lg:text-base font-bold tracking-wider rounded-full mx-1.5 sm:mx-2 uppercase focus:outline-none leading-tight space-x-1 lg:space-x-1.5">
    <span>Buy ShibaETH</span>
  </a>
  <a href="https://dex.guru/token/0x440c031d48cac6a2242521a2bcd0574bf74f6cac-eth" target="_blank" className="flex items-center flex-shrink-0 text-white bg-transparent border-2 border-gray-600 hover:border-gray-500 transform px-5 py-3 lg:px-10 lg:py-5 mt-2 text-xs md:text-sm lg:text-base font-bold tracking-wider rounded-full mx-1.5 sm:mx-2 uppercase focus:outline-none leading-tight space-x-1 lg:space-x-1.5">
    <span>View Charts</span>
  </a>
</div>
<div className="mt-4 md:mt-6 mx-auto lg:mx-0 inline-block">
  <div className="flex">
    <div className="flex-shrink-0">
      <InformationCircleIcon className="h-5 w-5 text-yellow-300" aria-hidden="true" />
    </div>
    <div className="ml-1.5 sm:ml-3 flex-1 md:flex md:justify-between mt-0.5 sm:mt-0">
      <p className="text-xs sm:text-sm text-yellow-300">Use the following slippage - <strong>15% to Buy</strong>, <strong>18% to Sell</strong></p>
    </div>
  </div>
</div>
</div>
                

              </div>
              <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                <div className="relative mx-auto w-56 sm:w-64 md:max-w-sm md:w-full lg:max-w-none">
                  <StaticImage
                    src="../images/hero.png"
                    className="w-full block"
                    width={800}
                    alt=""
                    loading="eager"
                    objectFit="contain"
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      {/* <div className="bg-white border-b border-gray-200">
        <div className="container py-8 lg:py-12">
          <div className="grid grid-cols-2 gap-8  sm:grid-cols-6 lg:grid-cols-3">
            <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
              <a href="#" target="_blank">
                <img className="h-8 md:h-11 lg:h-12 filter grayscale hover:filter-none transition-all opacity-60 hover:opacity-100" src={CoinGecko} alt="CoinGecko" />
              </a>

            </div>
            <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
              <a href="#" target="_blank">
                <img className="h-7 md:h-10 lg:h-11 filter grayscale hover:filter-none opacity-40 hover:opacity-100 transition-all" src={CoinMarketCap} alt="CoinMarketCap" />
              </a>
            </div>
            <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
              <a href="#" target="_blank">
                <img className="h-7 md:h-10 lg:h-11 filter grayscale hover:filter-none opacity-50 hover:opacity-100 transition-all" src={BSCScan} alt="BSCScan" />
              </a>
            </div>
          </div>
        </div>
      </div> */}
      


      <div className="bg-white overflow-hidden" id="features">
        <div className="relative container py-16 sm:py-24 lg:py-32">
          <svg
            className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-indigo-100" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={784} fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
          </svg>

          <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
            <div className="lg:col-span-1 text-center lg:text-left">
              <h2 className="text-base lg:text-lg font-bold tracking-wider text-indigo-600 uppercase">Features</h2>
              <p className="mt-2 text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl lg:text-5xl">
                Why invest in ShibaETH?
              </p>
              {/* <a href="https://tinyurl.com/dogebtcaudit" target="_blank" className="rounded-full bg-white border-2 border-green-500 hover:bg-green-50 inline-flex items-center py-3 px-5 mt-6">
                <LockClosedIcon className="w-6 h-6 flex-shrink-0 text-green-600" />
                <div className="text-left text-gray-800 ml-2.5 flex items-center space-x-4">
                  <h5 className="font-medium text-base md:text-lg text-green-600">Certik Audit</h5>
                </div>
                <ExternalLinkIcon className="w-4 h-4 ml-2 flex-shrink-0 text-gray-500" />
              </a> */}
            </div>
            <dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
              {features.map((feature) => (
                <div key={feature.name} className="text-center lg:text-left">
                  <dt>
                    <div className="flex items-center justify-center h-14 w-14 md:h-16 md:w-16 rounded-full bg-indigo-100 text-indigo-500 mx-auto lg:mx-0">
                      <feature.icon className="h-6 w-6 md:h-8 md:w-8" aria-hidden="true" />
                    </div>
                    <p className="mt-5 text-lg md:text-xl font-medium text-gray-900">{feature.name}</p>
                  </dt>
                  {feature.description ? (
                    <dd className="mt-1 text-xs md:text-sm text-gray-500">{feature.description}</dd>
                  ) : null}
                  {/* <dd className="mt-2 text-base md:text-lg text-gray-500">{feature.description}</dd> */}
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <div className="relative py-16 sm:py-24 lg:py-32 bg-indigo-50 z-20 pb-4 sm:pb-6 lg:pb-8" id="roadmap">
        <div className="container">
          <div className="space-y-12 md:space-y-16">
            <div className="text-center">
              <h2 className="text-base lg:text-lg font-bold tracking-wider text-indigo-600 uppercase">Roadmap</h2>
              <p className="mt-2 text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl lg:text-5xl">
                See Our Progress
              </p>
              <p className="mt-5 max-w-prose mx-auto text-base md:text-lg lg:text-xl text-gray-700">
                We have many great ideas that we're excited to bring to the holders of ShibaETH. Got any ideas of your own? We love community input - let us know!
              </p>
            </div>
            <ul className="mx-auto space-y-6 sm:grid md:grid-cols-2 sm:gap-8 sm:space-y-0 xl:grid-cols-3 text-gray-900">
              {roadmap.map((quarter, index) => (
                <li className="rounded-lg bg-white shadow-md p-8 sm:p-12 lg:p-14" key={index}>
                  <div className="flex items-center justify-center h-14 w-14 md:h-16 md:w-16 rounded-full bg-indigo-100 text-indigo-500">
                    <img className="h-6 w-6 md:h-8 md:w-8" src={quarters[index].icon} aria-hidden="true" />
                  </div>
                  <h3 className="text-2xl lg:text-3xl font-medium mb-8 mt-5">{quarters[index].quarter}</h3>
                  <div className="flow-root">
                    <div className="-mb-8">
                      {quarter.map((item, index) => (
                        <div key={index}>
                          <div className="relative pb-8">
                            {index !== quarter.length - 1 ? (
                              <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-indigo-100" aria-hidden="true" />
                            ) : null}
                            <div className="relative flex space-x-3">
                              <div>
                                <span
                                  className={classNames(
                                    item.iconBackground,
                                    'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                  )}
                                >
                                  {item.icon !== null ? (
                                    <item.icon className="h-5 w-5 text-white" aria-hidden="true" />
                                  ) : null}

                                </span>
                              </div>
                              <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                <div>
                                  <p className="text-sm lg:text-base text-gray-800">
                                    {item.content}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                </li>
              ))}


            </ul>
          </div>
        </div>
      </div>
      <div className="relative py-16 sm:py-24 lg:py-32 bg-indigo-50 z-20" id="team">
        <div className="container">
          <div className="space-y-12 md:space-y-16">
            <div className="text-center">
              <h2 className="text-base lg:text-lg font-bold tracking-wider text-indigo-600 uppercase">Team</h2>
              <p className="mt-2 text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl lg:text-5xl">
                Meet Our Core Team
              </p>
              <p className="mt-5 max-w-prose mx-auto text-base md:text-lg lg:text-xl text-gray-700">
                We're a passionate group of crypto-enthusiasts, dedicated to bringing the best token to your wallet
              </p>
            </div>
            <ul className="mx-auto space-y-12 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 xl:grid-cols-4 text-center text-gray-900">
              {team.map((team) => (
                <li>
                  <div className="space-y-6">
                    <img src={team.imageUrl} className="mx-auto h-40 w-40 xl:w-56 xl:h-56" />
                    <div className="space-y-2">
                      <div className="leading-6 space-y-1">
                        <h3 className="text-2xl md:text-3xl font-bold">{team.name}</h3>
                        <p className="text-indigo-600 text-base font-medium md:text-lg">{team.role}</p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="relative pt-16 sm:pt-24 lg:pt-32 bg-white z-20" id="faqs">
        <div className="container max-w-5xl">
          <div className="">
            <div className="text-center">
              <h2 className="text-base lg:text-lg font-bold tracking-wider text-indigo-600 uppercase">FAQs</h2>
              <p className="mt-2 text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl lg:text-5xl">
                Got any questions?
              </p>
            </div>
            <dl className="mt-8 space-y-6 divide-y divide-gray-200">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-700">
                          <span className="font-medium text-gray-900 text-lg md:text-xl">{faq.question}</span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base md:text-lg text-gray-700">{parse(faq.answer)}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <Cta />
    </Layout>
  )
}

export default IndexPage

