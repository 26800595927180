import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  Twitter,
  Reddit,
  Instagram,
  Telegram,
  Discord
} from '@icons-pack/react-simple-icons';

const socials = [
  {
    name: 'Twitter',
    href: 'https://twitter.com/TheRealShibaETH',
    icon: Twitter,
  },
  {
    name: 'Reddit',
    href: 'https://www.reddit.com/r/TheRealShibaETH/',
    icon: Reddit,
  },
  {
    name: 'Telegram',
    href: 'https://t.me/TheRealShibaETH',
    icon: Telegram,
  },
]



export default function Cta() {
  return (
    <div>
      <div className="relative py-16 sm:py-24 lg:py-32 bg-white z-20" id="cta">
        <div className="container max-w-5xl">
          <div className="bg-gradient-to-tr from-indigo-600 to-indigo-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4 items-center">
            <div className="pt-10 pb-12 px-6 sm:pt-14 sm:px-14 lg:py-14 lg:pr-0 xl:py-16 xl:px-16">
              <div className="lg:self-center">
                <h2 className="text-3xl font-bold text-white sm:text-4xl">
                  <span className="block">Join Our Community</span>
                </h2>
                <p className="mt-4 text-base leading-6 text-indigo-100 lg:text-lg">
                  Join the most exciting & rewarding token on the Ethereum Chain!
                </p>
                <a href="https://app.uniswap.org/#/swap?outputCurrency=0x440c031d48cac6a2242521a2bcd0574bf74f6cac&use=V2" target="_blank" className="mt-8 inline-flex items-center flex-shrink-0 text-indigo-700 bg-white transform hover:bg-indigo-50  px-4 py-2.5 lg:px-8 lg:py-4 text-xs md:text-sm font-bold tracking-wider rounded-full uppercase focus:outline-none leading-tight space-x-1 lg:space-x-1.5">
                    <span>Buy ShibaETH</span>
                  </a>
                <div className="flex space-x-5 mt-10">
                  {socials.map((item) => (
                    <a key={item.name} href={item.href} className="text-white hover:text-white">
                      <span className="sr-only">{item.name}</span>
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="-mt-12 lg:mt-0 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
              <StaticImage
                src="../images/doge-computer.png"
                className="transform rounded-md object-cover translate-x-12 sm:translate-x-0 object-center"
                width={1000}
                alt=""
                objectFit="contain"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}